import ApiService from '../api/api'
import DateHelper from '@/util/DateHelper'

const state = () => ({
  user_data: {}
})

const getters = {
  getUserData: state => state.user_data,
  getFullName: state => (state.user_data.last_name ? `${state.user_data.first_name} ${state.user_data.last_name}` : ''),
  getUsername: state => (state.user_data.username ? state.user_data.username : ''),
  getEmail: state => (state.user_data.email_address ? state.user_data.email_address : ''),
  getStatsMonthsToExport: state => tillDate => {
    let maximumStatsAge = 24
    let monthsSinceJoinDate = 1
    if (state.user_data.joindate && state.user_data.joindate !== '0001-01-01 00:00:00' && state.user_data.joindate !== '0000-00-00 00:00:00') {
      let joinDate = new Date(state.user_data.joindate)
      monthsSinceJoinDate = DateHelper.getMonthsBetween(tillDate, joinDate) + 1
    }

    return Math.min(maximumStatsAge, monthsSinceJoinDate)
  }
}

const mutations = {
  setUserData: (state, data) => (state.user_data = { ...state.user_data, ...data })
}

const actions = {
  init: context => {
    return context.dispatch('fetchData')
  },
  fetchData: async context => {
    let response = await ApiService.get('/ui/account')

    if (typeof response.data.data.sale_custom_text == 'string') {
      response.data.data.sale_custom_text = parseInt(response.data.data.sale_custom_text)
    }

    context.commit('setUserData', response.data.data)

    return response.data.data
  },
  saveData: async (context, userData) => {
    context.commit('setUserData', userData)

    await ApiService.put('/ui/account', userData)

    return userData
  }
}

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true
}
