import i18n from '../i18n'

const routes = [
  // StyleGuide
  {
    path: '/style',
    component: () => import('@/pages/style/Index')
  },
  {
    path: '/style/atoms',
    component: () => import('@/pages/style/Atoms')
  },
  {
    path: '/style/vcard-with-data-table',
    component: () => import('@/pages/style/VCardWithDataTable')
  },
  {
    path: '/style/vcard-with-form',
    component: () => import('@/pages/style/VCardWithForm')
  },
  {
    path: '/style/vcard-with-two-columns-form',
    component: () => import('@/pages/style/VCardWithTwoColumnsForm')
  },
  // Auth
  {
    path: '/auth/login',
    component: () => import('@/pages/auth/Login'),
    meta: { isPublic: true, title: i18n.t('auth.login') }
  },
  {
    path: '/auth/logout',
    name: 'logout',
    component: () => import('@/pages/auth/Logout'),
    meta: { title: i18n.t('auth.logout') }
  },
  // Dashboard
  {
    path: '/dashboard',
    alias: '/',
    name: 'dashboard',
    component: () => import('@/pages/dashboard/Index'),
    meta: { title: i18n.t('nav_drawer.dashboard') }
  },
  // Domains
  {
    path: '/domains',
    name: 'domains_index',
    component: () => import('@/pages/domain/Index'),
    meta: { title: i18n.t('nav_drawer.domains_overview'), parent: 'portfolio' }
  },
  {
    path: '/domains_add', // https://ticket.teaminternet.com/browse/PCREWBO-1971
    name: 'domains_add',
    component: () => import('@/pages/domain/Add'),
    meta: { title: i18n.t('nav_drawer.domains_add') }
  },
  {
    path: '/domain/edit',
    name: 'domains_edit_multiple',
    component: () => import('@/pages/domain/EditMultiple'),
    meta: { title: i18n.t('nav_drawer.domains_edit_multiple'), parent: 'portfolio' }
  },
  {
    path: '/domain/edit/:domain',
    name: 'domains_edit',
    component: () => import('@/pages/domain/Edit'),
    meta: { title: i18n.t('nav_drawer.domains_edit'), parent: 'portfolio' }
  },
  {
    path: '/domains_deleted',
    name: 'domains_deleted',
    component: () => import('@/pages/domain/Deleted'),
    meta: { title: i18n.t('nav_drawer.domains_deleted') }
  },
  // Tools
  {
    path: '/background_jobs',
    name: 'tools_background_jobs',
    component: () => import('@/pages/tools/background-jobs/Index.vue'),
    meta: { title: i18n.t('nav_drawer.background_jobs'), parent: 'tools' }
  },
  {
    path: '/blocked_adult_check',
    name: 'tools_blocked_adult_check',
    component: () => import('@/pages/tools/blocked-adult-check/Index.vue'),
    meta: { title: i18n.t('nav_drawer.blocked_adult_check'), parent: 'tools' }
  },
  {
    path: '/nameserver_check',
    name: 'tools_nameserver_check',
    component: () => import('@/pages/tools/nameserver-check/Index.vue'),
    meta: { title: i18n.t('nav_drawer.nameserver_check'), parent: 'tools' }
  },
  // Account Stats
  {
    path: '/stats/',
    component: () => import('@/pages/stats/Account'),
    children: [
      {
        path: 'account/date', //selected tab by default
        name: 'stats_index',
        component: () => import('@/pages/stats/account/Date'),
        meta: { title: i18n.t('nav_drawer.account_stats'), parent: 'statistics' }
      },
      {
        path: 'account/week',
        component: () => import('@/pages/stats/account/Week'),
        meta: { title: i18n.t('stats_week.heading'), parent: 'statistics' }
      },
      {
        path: 'account/month',
        component: () => import('@/pages/stats/account/Month'),
        meta: { title: i18n.t('stats_month.heading'), parent: 'statistics' }
      },
      {
        path: 'account/year',
        component: () => import('@/pages/stats/account/Year'),
        meta: { title: i18n.t('stats_year.heading'), parent: 'statistics' }
      }
    ]
  },
  // Domain performance
  {
    path: '/stats/domains',
    name: 'stats_domains',
    component: () => import('@/pages/stats/DomainSearch'),
    meta: { title: i18n.t('nav_drawer.domains_stats'), parent: 'statistics' }
  },
  // Domain Stats (not in menu only deep linked in the domain list)
  {
    path: '/stats/domain',
    component: () => import('@/pages/stats/Domain'),
    children: [
      {
        path: 'date/:domain?',
        alias: 'date',
        name: 'stats_domain_index',
        component: () => import('@/pages/stats/domain/Date'),
        meta: { title: i18n.t('stats_domain_date.heading'), parent: 'statistics' }
      },
      {
        path: 'week/:domain?',
        name: 'stats_domain_week',
        component: () => import('@/pages/stats/domain/Week'),
        meta: { title: i18n.t('stats_domain_week.heading'), parent: 'statistics' }
      },
      {
        path: 'month/:domain?',
        name: 'stats_domain_month',
        component: () => import('@/pages/stats/domain/Month'),
        meta: { title: i18n.t('stats_domain_month.heading'), parent: 'statistics' }
      },
      {
        path: 'year/:domain?',
        name: 'stats_domain_year',
        component: () => import('@/pages/stats/domain/Year'),
        meta: { title: i18n.t('stats_domain_year.heading'), parent: 'statistics' }
      }
    ]
  },

  // Folder Stats (not in menu only deep linked in the folder list)
  {
    path: '/stats/folder/:folder?',
    name: 'stats_folder',
    component: () => import('@/pages/stats/Folder'),
    meta: { title: i18n.t('stats_folder.heading') }
  },
  {
    path: '/exports',
    name: 'stats_exports',
    component: () => import('@/pages/stats/Exports'),
    meta: { title: i18n.t('nav_drawer.exports'), parent: 'statistics' }
  },
  // Folders
  {
    path: '/folders',
    name: 'folders_index',
    component: () => import('@/pages/folder/Index'),
    meta: { title: i18n.t('nav_drawer.folders_overview'), parent: 'portfolio' }
  },
  {
    path: '/folders/add',
    name: 'folders_add',
    component: () => import('@/pages/folder/Add'),
    meta: { title: i18n.t('folder_settings.heading_add'), parent: 'portfolio' }
  },
  {
    path: '/folders/edit/:folder_id',
    name: 'folders_edit',
    component: () => import('@/pages/folder/Edit'),
    meta: { title: i18n.t('folder_settings.heading_edit'), parent: 'portfolio' }
  },
  // Account menu
  {
    path: '/account/home',
    name: 'account_index',
    component: () => import('@/pages/account/Index'),
    meta: { title: i18n.t('account_home.heading') }
  },
  {
    path: '/account/edit',
    component: () => import('@/pages/account/Edit'),
    meta: { title: i18n.t('account_edit.heading') }
  },
  {
    path: '/account/settings',
    component: () => import('@/pages/account/Settings'),
    meta: { title: i18n.t('account_settings.heading') }
  },
  {
    path: '/account/payment',
    component: () => import('@/pages/account/Payment'),
    meta: { title: i18n.t('payment_details.heading') }
  },
  {
    path: '/account/balance_history',
    component: () => import('@/pages/account/BalanceHistory'),
    meta: { title: i18n.t('balance_history.heading') }
  },
  {
    path: '/account/referral_program',
    component: () => import('@/pages/account/ReferralProgram'),
    meta: { title: i18n.t('referral_program.heading') }
  },
  // Misc
  {
    path: '/help',
    name: 'api_documentation',
    component: () => import('@/pages/help/Index'),
    meta: { title: i18n.t('help.heading') }
  },
  {
    path: '/imprint',
    component: () => import('@/pages/imprint/Index'),
    meta: { isPublic: true, title: i18n.t('imprint.heading') }
  },
  {
    path: '/ab-test',
    component: () => import('@/pages/ab-test/Example'),
    meta: { title: i18n.t('ab_test.heading') }
  },
  {
    path: '/config',
    component: () => import('@/pages/config/Index'),
    meta: { title: i18n.t('config.heading') }
  },
  {
    path: '/support',
    name: 'support',
    component: () => import('@/pages/support/Index'),
    meta: { title: i18n.t('contact_support.heading') }
  }
]

const loggedInStoreAction = 'auth/loggedIn'
const loginRoute = '/auth/login'

export default { routes, loggedInStoreAction, loginRoute }
