<template>
  <v-app-bar id="appbar" app clipped-left dark elevation="0" height="60">
    <v-app-bar-nav-icon @click="onNavbarToggleHandler" v-if="loggedIn" class="app-bar-nav-icon"></v-app-bar-nav-icon>
    <AppBarLogo class="ml-2" />
    <AppBarEnv />
    <template v-if="loggedIn">
      <v-spacer />
      <AppBarAccountMenu />
      <v-btn class="ma-2" icon to="/auth/logout"><v-icon>mdi-logout</v-icon> </v-btn>
    </template>
  </v-app-bar>
</template>

<script>
import AppBarLogo from './AppBarLogo'
import AppBarEnv from './AppBarEnv'
import AppBarAccountMenu from './AppBarAccountMenu'

export default {
  components: {
    AppBarLogo,
    AppBarEnv,
    AppBarAccountMenu
  },
  computed: {
    loggedIn() {
      return this.$store.getters['auth/loggedIn']
    }
  },
  methods: {
    onNavbarToggleHandler() {
      this.$emit('toggleNavbarVisibility')
    }
  }
}
</script>

<style lang="sass">
@import '~@/sass/variables.sass'

#appbar
  // make sure the appbar is on top of navdrawer
  z-index: 8

  background: linear-gradient(90deg, $header-gradient-left 0%, $header-gradient-right 100%)
  a
    color: #FFFFFF !important
</style>
