const defaultDatePickerLimitForSelectedDates = require('@/config').default.datePickerLimitForSelectedDates

let DateHelper = {
  /**
   * @param date
   * @returns {string|null|undefined}
   */
  toStringOrDefaultMinDate(date) {
    if (date === false) {
      return undefined
    }

    if (date === null) {
      let date = this.today()
      date.setDate(date.getDate() - defaultDatePickerLimitForSelectedDates)
      return this.toDateString(date)
    }

    if (typeof date === 'string') {
      return date
    }

    return null
  },
  /**
   * @returns {string}
   */
  firstDayOfCurrentWeekAsString() {
    return this.toDateString(this.previousMonday(this.today()))
  },
  /**
   * @returns {string}
   */
  firstDayOfPreviousWeekAsString() {
    let today = this.today()
    today.setDate(today.getDate() - 7)
    return this.toDateString(this.previousMonday(today))
  },
  /**
   * @param date
   * @returns {Date}
   */
  previousMonday(date) {
    date.setDate(date.getDate() - ((date.getDay() + 6) % 7))
    return date
  },
  /**
   * @param date
   * @returns {Date}
   */
  firstDayOfMonth(date) {
    // when the day is the first day just return it
    if (date.getDate() === 1) {
      return date
    }
    let result = new Date()
    result.setFullYear(date.getFullYear())
    result.setMonth(date.getMonth())
    result.setDate(1)
    return result
  },
  /**
   * @param date
   * @returns {Date}
   */
  firstDayOfYear(date) {
    // when the day is the first day just return it
    if (date.getDate() === 1) {
      return date
    }
    let result = new Date()
    result.setFullYear(date.getFullYear())
    result.setMonth(0)
    result.setDate(1)
    return result
  },
  /**
   * @param date
   * @returns {Date}
   */
  lastDayOfYear(date) {
    let result = new Date()
    result.setFullYear(date.getFullYear() + 1)
    result.setMonth(0)
    result.setDate(0)
    return result
  },
  /**
   * @param date
   * @returns {Date}
   */
  lastDayOfMonth(date) {
    let result = new Date()
    result.setFullYear(date.getFullYear())
    result.setMonth(date.getMonth() + 1)
    result.setDate(0)
    return result
  },
  /**
   * @returns {Date}
   */
  firstDayOfCurrentMonth() {
    return this.firstDayOfMonth(this.today())
  },
  /**
   * @returns {Date}
   */
  firstDayOfCurrentYear() {
    return this.firstDayOfYear(this.today())
  },
  /**
   * @returns {Date}
   */
  firstDayOfPreviousMonth() {
    let date = this.today()
    date.setMonth(date.getMonth() - 1)
    return this.firstDayOfMonth(date)
  },
  /**
   * @returns {string}
   */
  firstDayOfCurrentMonthAsString() {
    let date = this.firstDayOfCurrentMonth()
    return this.toDateString(date)
  },
  /**
   * @returns {string}
   */
  firstDayOfPreviousMonthAsString() {
    let date = this.firstDayOfPreviousMonth()
    return this.toDateString(date)
  },
  /**
   * @returns {string}
   */
  firstDayOfMonthOneYearAgoAsString() {
    let from = this.firstDayOfCurrentMonth()
    from.setFullYear(from.getFullYear() - 1)
    return this.toDateString(from)
  },

  /**
   * This function can calculate the first day of a given year and will return the correct date:
   * Input: 2020
   * Output: 2020-01-01
   *
   * @param year
   * @returns {string}
   */
  firstDayOfYearAsString(year) {
    let date = this.today()

    if (typeof year !== 'undefined') {
      date.setFullYear(year)
    }

    date.setMonth(0)
    date.setDate(1)

    return this.toDateString(date)
  },

  /**
   * Returns this first day of the last year as string.
   *
   * @returns {Date}
   */
  firstDayOfPreviousYear() {
    let date = this.today()
    date.setFullYear(date.getFullYear() - 1)
    return this.firstDayOfYear(date)
  },

  /**
   * Returns this first day of the last year as string.
   *
   * @returns {Date}
   */
  lastDayOfPreviousYear() {
    let date = this.today()
    date.setFullYear(date.getFullYear() - 1)
    return this.lastDayOfYear(date)
  },

  /**
   * Returns this first day of the last year as string.
   *
   * @param year
   * @returns {string}
   */
  firstDayOfPreviousYearAsString(year) {
    let date = this.today()

    if (typeof year !== 'undefined') {
      date.setFullYear(year)
    }
    return this.firstDayOfYearAsString(date.getFullYear() - 1)
  },

  /**
   * This function returns the current year as number
   * @returns {number}
   */
  getCurrentYear(year) {
    let date = this.today()

    if (typeof year !== 'undefined') {
      date.setFullYear(year)
    }

    return date.getFullYear()
  },
  /**
   * Generates a date in the format YYYY-mm-dd.
   *
   * @param date
   * @returns {string}
   */
  toDateString(date) {
    return date.toISOString().substr(0, 10)
  },
  /**
   * Generates the month of a date in the format YYYY-mm.
   *
   * @param date
   * @returns {string}
   */
  toMonthString(date) {
    return date.toISOString().substr(0, 7)
  },
  /**
   * @returns {Date}
   */
  today() {
    return new Date()
  },
  /**
   * @returns {string}
   */
  todayAsString() {
    return this.toDateString(this.today())
  },
  /**
   * @returns {Date}
   */
  yesterday() {
    return new Date(new Date().setDate(new Date().getDate() - 1))
  },
  /**
   * @returns {string}
   */
  yesterdayAsString() {
    return this.toDateString(this.yesterday())
  },
  /**
   * @param daysAgo
   * @returns {Date}
   */
  daysAgo(daysAgo) {
    let date = DateHelper.today()
    date.setDate(date.getDate() - daysAgo)
    return date
  },
  /**
   * @param daysAgo
   * @returns {string}
   */
  daysAgoAsString(daysAgo) {
    return DateHelper.toDateString(this.daysAgo(daysAgo))
  },
  /**
   * @param date
   * @returns {boolean}
   */
  isMonday(date) {
    return date.getDay() === 1
  },
  /**
   * @param date
   * @returns {boolean}
   */
  isSunday(date) {
    return date.getDay() === 0
  },
  /**
   * @param startDate
   * @param monthsToShow = 1
   * @returns {*[]}
   */
  getMonthlySlices(startDate, monthsToShow = 1) {
    let dates = []
    let currentDate = this.firstDayOfMonth(startDate)
    for (let currentMonth = 1; currentMonth <= monthsToShow; currentMonth++) {
      let currentSlice = {
        start: DateHelper.toDateString(DateHelper.firstDayOfMonth(currentDate)),
        end: DateHelper.toDateString(DateHelper.lastDayOfMonth(currentDate)),
        label: currentDate.getFullYear() + '/' + (currentDate.getMonth() + 1).toString().padStart(2, '0'),
        file_suffix: currentDate.getFullYear() + '_' + (currentDate.getMonth() + 1).toString().padStart(2, '0')
      }
      dates.push(currentSlice)
      currentDate.setMonth(currentDate.getMonth() - 1)
    }

    return dates
  },
  getMonthsBetween(start, end) {
    let months
    months = (start.getFullYear() - end.getFullYear()) * 12
    months -= end.getMonth()
    months += start.getMonth()
    return months <= 0 ? 0 : months
  },
  getWeekday(date) {
    let d = new Date(date)
    let weekdays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
    return weekdays[d.getDay()]
  }
}

export default DateHelper
