export default {
  background: '#FAFAFD',
  sheet_background: '#FFFFFF',
  sheet_border: '#CDCDCD',
  primary: '#1748B8',
  primary_light: '#B4CAED',
  secondary: '#1748B8',
  header_gradient_left: '#000000',
  header_gradient_right: '#000000',
  logo_color: '#E6462E',
  primary_text: '#000000',
  secondary_text: '#85827F',
  input_background: '#F3F3F3',
  input_border: '#CDCDCD',
  error: '#B71C1C',
  info: '#2196F3',
  success: '#25952A',
  warning: '#FFA000'
}
