let objectHash = require('object-hash')

let BatchHelper = {
  getUniqueErrorMessages(batchResponses) {
    if (typeof batchResponses !== 'object') {
      return []
    }

    let uniqueErrorMessage = {}

    batchResponses.map(batchResponse => {
      if (batchResponse.body === null || batchResponse.body === undefined) {
        return batchResponse
      }
      if (batchResponse.code < 300 || batchResponse.code === null) {
        return batchResponse
      }
      if (batchResponse.body.message === null) {
        return batchResponse
      }
      let messageHash = objectHash(batchResponse.body.message)
      uniqueErrorMessage[messageHash] = batchResponse.body.message
    })

    return Object.values(uniqueErrorMessage)
  },
  getIdsErrorMessages(batchResponses) {
    let ids = []

    if (Array.isArray(batchResponses)) {
      batchResponses.forEach(batchResponse => {
        if (batchResponse.code < 200 || batchResponse.code >= 300) {
          ids.push(batchResponse.id)
        }
      })
    }

    return ids
  }
}

export default BatchHelper
