class StoreHelper {
  apiUrl = ''
  fetchDataParams = ''

  constructor(apiService, apiUrl, fetchDataParams = '') {
    this.apiService = apiService
    this.apiUrl = apiUrl
    this.fetchDataParams = fetchDataParams
  }

  getInitialState() {
    return {
      response_data: []
    }
  }

  getGetters() {
    return {
      getData: state => state.response_data,
      getApiUrl: () => this.apiUrl,
      dataExists: state => !!state.response_data.data,
      getAll: state => () => {
        if (!state.response_data.data || !state.response_data.data.data) {
          return []
        }

        return state.response_data.data.data
      },
      getById: state => id => {
        if (!state.response_data.data || !state.response_data.data.data) {
          return null
        }

        return state.response_data.data.data.filter(item => item.id == id)[0]
      }
    }
  }

  getMutations() {
    return {
      setData: (state, data) => (state.response_data = data),
      resetState: state => Object.assign(state, this.getInitialState())
    }
  }

  getActions() {
    return {
      fetchData: async (context, data) => {
        let apiUrl = this.apiUrl

        if (this.fetchDataParams.length > 0) {
          apiUrl += this.fetchDataParams
        }

        let response = await this.apiService.get(apiUrl, data).catch(error => {
          context.commit('resetState')
          throw error
        })

        response = this.getHelpers().castCustomTextToInteger(response)

        context.commit('resetState')
        context.commit('setData', response.data)
        return response.data
      },
      getData: async (context, data) => {
        if (context.getters['dataExists']) {
          return context.state.response_data
        } else {
          return context.dispatch('fetchData', data)
        }
      },
      resetState({ commit }) {
        commit('resetState')
      }
    }
  }

  getHelpers() {
    const helpers = {
      castCustomTextToInteger(response) {
        // Transform custom text when it is present
        if (response.data && response.data.data && Array.isArray(response.data.data.data)) {
          response.data.data.data.map(item => {
            if (typeof item.custom_text == 'string') {
              item.custom_text = parseInt(item.custom_text)
            }
          })
        }

        return response
      }
    }

    return helpers
  }
}

export default StoreHelper
