let urlParametersHelper = {
  hasFixedParameters(route) {
    return Object.keys(this.getFixedParameters(route)).length > 0
  },
  getFixedParameters(route) {
    let params = Object.keys(route.query)
    let fixedParams = {}
    const availableDebugParams = ['show_query_log', 'show_debug_log', 'debug_secret']

    params.forEach(param => {
      if (param.startsWith('ab_test')) {
        if (typeof fixedParams['ab_test_params'] === 'undefined') {
          fixedParams['ab_test_params'] = {}
        }
        fixedParams['ab_test_params'][param] = route.query[param]
      }

      if (availableDebugParams.includes(param)) {
        if (typeof fixedParams['debug_params'] === 'undefined') {
          fixedParams['debug_params'] = {}
        }
        fixedParams['debug_params'][param] = route.query[param]
      }
    })

    return fixedParams
  }
}

export default urlParametersHelper
