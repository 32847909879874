import StoreHelper from './store_helper'
import ApiService from '../api/api'

const storeHelper = new StoreHelper(ApiService, '/ui/sale_banners')
const state = () => storeHelper.getInitialState()
const mutations = storeHelper.getMutations()
const getters = {
  ...storeHelper.getGetters(),
  getBannerNameById: state => id => {
    let banner = getters.getById(state)(id)
    if (banner == null) {
      return ''
    }
    return banner.name
  }
}
const actions = storeHelper.getActions()

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true
}
