import store from '../store'
import router from '../router/router'
import Logger from '@/util/Logger'

const publicEndpoints = ['/ui/auth/login', '/ui/auth/refreshtoken', '/prokura/auth/userlogin']

const isPublicEndpoint = function(endpoint) {
  return publicEndpoints.includes(endpoint)
}

const getRefreshTokenRoute = function() {
  return '/ui/auth/refreshtoken'
}

const getAuthData = function() {
  return store.state.auth
}

const setAuthData = function(data) {
  store.commit('auth/setAuthResponseData', data)
}
const clearAuthData = function() {
  store.commit('auth/clearAuthResponseData')
}

const onTokenRefreshFailed = function() {
  if (router.currentRoute && router.currentRoute.path !== '/auth/login') {
    Logger.log('[Api] Redirect to login')
    router.push({ path: '/auth/login' })
  }
}

export default { isPublicEndpoint, getRefreshTokenRoute, getAuthData, setAuthData, clearAuthData, onTokenRefreshFailed }
