import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import i18n from '../i18n'
import '@mdi/font/css/materialdesignicons.css'
import ClassicTheme from '@/themes/classic.js'
import NewUITheme from '@/themes/new-ui.js'
import DarkVioletTheme from '@/themes/dark-violet.js'
import CosmicBlueTheme from '@/themes/cosmic-blue.js'
import DeepPurpleTheme from '@/themes/deep-purple.js'
import ChocolatTheme from '@/themes/chocolat.js'
import SpringForestTheme from '@/themes/spring-forest.js'
import LocalStore from '@/util/LocalStore'

Vue.use(Vuetify)

const themes = {
  classic: ClassicTheme,
  'new-ui': NewUITheme,
  'dark-violet': DarkVioletTheme,
  'cosmic-blue': CosmicBlueTheme,
  'deep-purple': DeepPurpleTheme,
  chocolat: ChocolatTheme,
  'spring-forest': SpringForestTheme
}
const defaultTheme = LocalStore.getPcTheme() ?? 'deep-purple'

export default new Vuetify({
  lang: {
    t: (key, ...params) => i18n.t(key, params)
  },
  icons: {
    iconfont: 'mdi',
    values: {}
  },
  customVariables: ['@/sass/variables.sass'],
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: themes[defaultTheme]
    }
  }
})

window.setTheme = function(theme) {
  window.app.$vuetify.theme.themes.light = themes[theme]
}
