import StoreHelper from './store_helper'
import ApiService from '../api/api'

const storeHelper = new StoreHelper(ApiService, '/ui/account/permissions')
const state = () => storeHelper.getInitialState()
const mutations = storeHelper.getMutations()

const getters = {
  ...storeHelper.getGetters(),
  hasPermission: state => permission => state.response_data.data.includes(permission)
}

const actions = storeHelper.getActions()

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true
}
