import StoreHelper from './store_helper'

class StatsHelper extends StoreHelper {
  constructor(apiService, apiUrl, fetchDataParams = '') {
    super(apiService, apiUrl, fetchDataParams)
  }

  getInitialState() {
    return {
      ...super.getInitialState(),
      avg: {
        crt: 0,
        rpc: 0,
        rpm: 0
      },
      sum: {
        clicks: 0,
        uniques: 0,
        revenue: 0
      }
    }
  }

  getHelpers() {
    const helpers = {
      calculateSum: data => {
        return data.reduce((prev, cur) => {
          return {
            clicks: parseInt(prev.clicks) + parseInt(cur.clicks),
            uniques: parseInt(prev.uniques) + parseInt(cur.uniques),
            revenue: parseFloat(prev.revenue) + parseFloat(cur.revenue)
          }
        })
      },
      calculateAvg: (sum, itemCount) => {
        let avg = {
          clicks: itemCount > 0 ? sum.clicks / itemCount : 0.0,
          uniques: itemCount > 0 ? sum.uniques / itemCount : 0.0,
          revenue: itemCount > 0 ? sum.revenue / itemCount : 0.0
        }
        avg.ctr = avg.uniques ? (avg.clicks / avg.uniques) * 100 : 0
        avg.rpc = avg.clicks ? avg.revenue / avg.clicks : 0
        avg.rpm = avg.uniques ? (avg.revenue / avg.uniques) * 1000 : 0

        return avg
      },
      resetSummary: context => {
        context.commit('setSum', this.getInitialState().sum)
        context.commit('setAvg', this.getInitialState().avg)
      },
      calculateSummary: (context, data) => {
        if (!Array.isArray(data)) {
          helpers.resetSummary(context)
          return
        }
        let n = data.length
        if (n === 0) {
          helpers.resetSummary(context)
          return
        }

        let sum = helpers.calculateSum(data)
        context.commit('setSum', sum)

        let avg = helpers.calculateAvg(sum, n)
        context.commit('setAvg', avg)
      }
    }

    return helpers
  }

  getGetters() {
    return {
      ...super.getGetters(),
      getSum: state => state.sum,
      getAvg: state => state.avg
    }
  }

  getMutations() {
    return {
      ...super.getMutations(),
      setSum: (state, sum) => (state.sum = sum),
      setAvg: (state, avg) => (state.avg = avg)
    }
  }

  getActions() {
    return {
      fetchData: async (context, data) => {
        let apiUrl = this.apiUrl

        if (this.fetchDataParams.length > 0) {
          apiUrl += this.fetchDataParams
        }

        let response = await this.apiService.get(apiUrl, data).catch(error => {
          context.commit('setData', this.getInitialState().response_data)
          this.getHelpers().resetSummary(context)
          throw error
        })
        context.commit('setData', response.data)
        this.getHelpers().calculateSummary(context, response.data.data.data)
        return response.data
      }
    }
  }
}

export default StatsHelper
