import StoreHelper from './store_helper'
import ApiService from '../api/api'

const systemFolders = [{ name: 'Default', id: 1 }]
const apiUrl = '/ui/folders'
const storeHelper = new StoreHelper(ApiService, apiUrl)
const state = () => {
  return {
    ...storeHelper.getInitialState(),
    selectedFolder: null
  }
}
const mutations = storeHelper.getMutations()
const getters = {
  ...storeHelper.getGetters(),
  getSystemAndUserFolders: state => {
    if (typeof state.response_data.data === 'undefined') {
      return systemFolders
    }

    return systemFolders.concat(state.response_data.data.data)
  },
  getNameById: state => id => {
    let folders = getters.getSystemAndUserFolders(state)
    let requestedFolder = folders.filter(item => item.id == id)[0]

    if (!requestedFolder || !requestedFolder.name) {
      return id
    }

    return requestedFolder.name
  }
}
const actions = {
  ...storeHelper.getActions(),
  update: async (context, data) => {
    let response = await ApiService.put(apiUrl + '/' + data.folder, data.folderData)
    return response.data
  },
  store: async (context, data) => {
    let response = await ApiService.post(apiUrl, data)
    return response.data
  },
  delete: (context, folder) => ApiService.delete(apiUrl + '/' + folder),
  fetch: async (context, folder) => {
    if (context.state.selectedFolder !== null) {
      return context.state.selectedFolder
    } else {
      let response = await ApiService.get(apiUrl + '/' + folder, {})
      if (response.data && response.data.data.custom_text) {
        if (typeof response.data.data.custom_text == 'string') {
          response.data.data.custom_text = parseInt(response.data.data.custom_text)
        }
        context.commit('setFolder', response.data.data)
      }
      return response.data.data
    }
  }
}
export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true
}
