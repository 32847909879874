import Router from 'vue-router'
import store from '../store'
import i18n from '../i18n'

const router_context = require(`@/router/context`).default

let routes = []
routes = routes.concat(router_context.routes)

let pageNotFound = {
  path: '*',
  component: () => import('@/pages/app/PageNotFound'),
  meta: { hideInMenu: true, isPublic: true, title: i18n.t('page_not_found.tab_heading') }
}

routes.push(pageNotFound)

const router = new Router({
  mode: 'history',
  routes: routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  }
})

// navigation guard
const guard = function(to, from, next) {
  // check if logged in => redirect login page to home page
  let redirectToHome = to.matched.some(record => record.path === router_context.loginRoute) && store.getters[router_context.loggedInStoreAction]
  // check if forwarding from prokura => redirect to or stay on login page
  let forwardingFromProkura = (typeof to.query.userlogin !== 'undefined' && to.query.userlogin.indexOf('loginas') !== -1) ?? false
  if (redirectToHome && !forwardingFromProkura) {
    next({ path: '/' })
  } else {
    // allow public route
    if (to.matched.some(record => record.meta.isPublic)) {
      next()
    } else {
      // redirect to /login?redirect=something if user is not logged in
      if (!store.getters[router_context.loggedInStoreAction]) {
        next({ path: router_context.loginRoute, query: { redirect: to.fullPath } })
      } else {
        // user is logged in
        next()
      }
    }
  }
}
router.beforeEach(guard)

router.afterEach(to => {
  if (to.meta && to.meta.title) {
    document.title =
      to.meta.title + ' - ' + i18n.t('application.title') + (process.env.VUE_APP_ENV !== 'prod' ? ` :: ${process.env.VUE_APP_ENV}` : '')
  } else {
    document.title = i18n.t('application.title')
  }
})

export default router
