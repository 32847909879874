const state = () => ({
  isCompactView: true
})

const getters = {
  isCompactView: state => state.isCompactView
}

const mutations = {
  toggleCompactView(state) {
    state.isCompactView = !state.isCompactView
  }
}

const actions = {
  toggleCompactView: ({ commit }) => {
    commit('toggleCompactView')
  }
}

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true
}
