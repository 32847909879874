export default {
  background: '#FAFAFD',
  sheet_background: '#FFFFFF',
  sheet_border: '#CDCDCD',
  primary: '#312560',
  primary_light: '#EAE9F1',
  secondary: '#004970',
  header_gradient_left: '#B95636',
  header_gradient_right: '#312560',
  logo_color: '#FFFFFF',
  primary_text: '#1F1D1B',
  secondary_text: '#85827F',
  input_background: '#F3F3F3',
  input_border: '#CDCDCD',
  error: '#B71C1C',
  info: '#2196F3',
  success: '#25952A',
  warning: '#FFA000'
}
