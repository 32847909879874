import StoreHelper from './store_helper'
import ApiService from '../api/api'

const storeHelper = new StoreHelper(ApiService, '/ui/sale_urls')
const state = () => storeHelper.getInitialState()
const mutations = storeHelper.getMutations()
const getters = {
  ...storeHelper.getGetters(),
  getSaleUrls: state => () => {
    if (!state.response_data.data || !state.response_data.data.data) {
      return []
    }

    return state.response_data.data.data
  },
  getSaleUrlNameById: state => id => {
    let sale_url = getters.getById(state)(id)
    if (sale_url == null) {
      return ''
    }

    return sale_url.name
  },
  getSaleUrlLinkById: state => id => {
    let sale_url = getters.getById(state)(id)
    if (sale_url == null) {
      return ''
    }

    return sale_url.url
  }
}
const actions = {
  ...storeHelper.getActions(),
  saveData: async (context, data) => {
    let response = await ApiService.post(context.getters.getApiUrl, data)
    context.commit('setData', response.data)
    return response.data
  }
}

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true
}
