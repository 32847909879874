import StoreHelper from './store_helper'
import ApiService from '../api/api'

const apiUrl = '/ui/finished_imports/pending'
const storeHelper = new StoreHelper(ApiService, apiUrl)
const state = () => storeHelper.getInitialState()
const mutations = storeHelper.getMutations()
const getters = {
  ...storeHelper.getGetters(),
  getLastFinishedDay: state => {
    let firstPendingDate = new Date()
    if (typeof state.response_data.data === 'undefined') {
      return firstPendingDate
    }

    let froundInStore = false
    for (let item of state.response_data.data) {
      let itemDate = new Date(item.date)
      if (itemDate < firstPendingDate) {
        firstPendingDate = itemDate
        froundInStore = true
      }
    }

    if (froundInStore) {
      // we need to substract one day since the last finished day is the
      // day before the last pending day
      firstPendingDate.setDate(firstPendingDate.getDate() - 1)
    }

    return firstPendingDate
  }
}
const actions = storeHelper.getActions()

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true
}
