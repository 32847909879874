<template>
  <v-app :class="appClass">
    <AppBar @toggleNavbarVisibility="onToggleNavbarVisibilityHandler" />
    <AppNavigation ref="appNavigation" />
    <v-main>
      <v-container fluid class="pa-4">
        <router-view />
      </v-container>
    </v-main>
    <SnackBar />
    <Footer />
  </v-app>
</template>

<script>
import SnackBar from '@/components/app/SnackBar.vue'
import AppBar from '@/components/app/AppBar.vue'
import AppNavigation from '@/components/app/AppNavigation.vue'
import Footer from '@/components/app/Footer.vue'

export default {
  components: {
    SnackBar,
    AppBar,
    AppNavigation,
    Footer
  },
  computed: {
    appClass() {
      let className = this.$store.getters['compact_view/isCompactView'] ? 'compact ' : ''
      className += process.env.VUE_APP_ENV !== 'prod' ? `env-${process.env.VUE_APP_ENV}` : ''
      return className
    },
    loggedIn() {
      return this.$store.getters['auth/loggedIn']
    }
  },
  methods: {
    onToggleNavbarVisibilityHandler() {
      this.$refs.appNavigation.toggleIsVisible()
    }
  },
  mounted() {
    window.document.body.classList.add('theme-default')
  }
}
</script>

<style lang="sass">
@import '~@/sass/app.sass'
</style>
