<template>
  <div id="appbar-accountmenu" v-if="userData">
    <v-menu bottom left offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" icon>
          <v-icon>mdi-account-outline</v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-list id="appbar-accountmenu-items">
          <v-list-item id="appbar-accountmenu-logininfo" two-line>
            <v-list-item-content data-matomo-mask>
              <v-list-item-title>{{ userData.username }}</v-list-item-title>
              <v-list-item-subtitle>{{ userData.email_address }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-divider class="mb-2" />
          <v-list-item to="/account/home" class="appbar-accountmenu-link">
            <v-list-item-title>Account Details</v-list-item-title>
          </v-list-item>
          <v-list-item to="/account/settings" class="appbar-accountmenu-link">
            <v-list-item-title>Default Settings</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
export default {
  computed: {
    userData() {
      return this.$store.getters['user/getUserData']
    }
  },
  mounted() {
    this.$store.dispatch('user/init')
  }
}
</script>
