const language = 'en'

/**
 * We need numbers in formated way
 *
 * @param input                 => number to formate
 * @param maximumFractionDigits => default are 2 digits after fraction
 * @param minimumFractionDigits => default are 2 digits after fraction
 * @param lang                  => default is 'en'
 * @returns {number}
 */
let number = function(input, maximumFractionDigits = 2, minimumFractionDigits = 2, lang = language) {
  let number = 0
  // Validate first the number
  if (typeof input === 'string') {
    if (input.indexOf('.') !== -1) {
      input = parseFloat(input)
    } else {
      input = parseInt(input)
    }
  }

  // Get formated number
  if (typeof input === 'number') {
    number = new Intl.NumberFormat(lang, {
      maximumFractionDigits: maximumFractionDigits,
      minimumFractionDigits: minimumFractionDigits
    }).format(input)
  }
  return number
}

let isValidDateString = function(inputDate) {
  return !(typeof inputDate === 'undefined' || inputDate === null || inputDate.substring(0, 10) === '0000-00-00')
}

/**
 * Some browsers (e.g. safari) can not parse dates with the format "YYYY-MM-dd H:i:s" and
 * need iso date "YYYY-MM-ddTH:i:s"
 * @param inputDate
 * @returns {*}
 */
let replaceSpaceWithDateDivider = function(inputDate) {
  return inputDate.replace(/ /g, 'T')
}

/**
 * @param error
 * @returns {string}
 */
let handleRangeError = function(error) {
  if (error instanceof RangeError) {
    return ''
  }
  throw error
}

let isValidDate = function(date) {
  return date instanceof Date && !isNaN(date)
}

/**
 * Formats a date as <Month> <Year>
 *
 * @param  inputDate => date to format
 * @param  lang => language to format
 * @returns string
 */
let shortMonth = function(inputDate, lang = language) {
  if (!isValidDateString(inputDate)) {
    return ''
  }

  inputDate = replaceSpaceWithDateDivider(inputDate)
  try {
    let dateAsObject = new Date(inputDate)
    let formatter = new Intl.DateTimeFormat(lang, { month: 'short' })
    return dateAsObject.getFullYear() + '-' + formatter.format(dateAsObject)
  } catch (error) {
    return handleRangeError(error)
  }
}

let shortYear = function(inputDate) {
  inputDate = String(inputDate)
  if (!isValidDateString(inputDate)) {
    return ''
  }

  inputDate = replaceSpaceWithDateDivider(inputDate)
  try {
    let dateAsObject = new Date(inputDate)
    if (!isValidDate(dateAsObject)) {
      return ''
    }
    return String(dateAsObject.getFullYear())
  } catch (error) {
    return handleRangeError(error)
  }
}

/**
 * Formats a date as <Year> <Week>
 *
 * @param  inputDate => date to format
 * @returns string
 */
let shortWeek = function(inputDate) {
  if (!isValidDateString(inputDate)) {
    return ''
  }

  inputDate = replaceSpaceWithDateDivider(inputDate)
  try {
    let dateAsObject = new Date(inputDate)
    if (!isValidDate(dateAsObject)) {
      return ''
    }
    let day = new Date(Date.UTC(dateAsObject.getFullYear(), dateAsObject.getMonth(), dateAsObject.getDate()))
    let dayNum = day.getUTCDay() || 7
    day.setUTCDate(day.getUTCDate() + 4 - dayNum)
    let yearStart = new Date(Date.UTC(day.getUTCFullYear(), 0, 1))
    let week = Math.ceil(((day - yearStart) / 86400000 + 1) / 7)
    let formattedYearStart = yearStart.getFullYear()
    let formattedWeek = (week < 10 ? '0' : '') + week
    return `${formattedYearStart}-W${formattedWeek}`
  } catch (error) {
    return handleRangeError(error)
  }
}

/**
 * Formats a date as short date
 *
 * @param  inputDate => date to format
 * @returns string
 */
let shortDate = function(inputDate) {
  if (!isValidDateString(inputDate)) {
    return ''
  }

  inputDate = replaceSpaceWithDateDivider(inputDate)
  try {
    let dateAsObject = new Date(inputDate)
    if (!isValidDate(dateAsObject)) {
      return ''
    }
    return dateAsObject.toISOString().split('T')[0]
  } catch (error) {
    return handleRangeError(error)
  }
}

/**
 * Formats price
 *
 * @param  value => price to format
 * @param currencyValue => the value of the currency
 * @param transformToZero => determines whether to transform null value to 0 or not
 * @returns string
 */
let price = function(value, currencyValue, transformToZero) {
  if (value === null && transformToZero) {
    value = 0
  }

  if (typeof value === 'undefined' || value === null) {
    return null
  }
  let formattedValue = number(value)
  let formattedCurrency = currency(currencyValue)

  return `${formattedCurrency} ${formattedValue}`.trim()
}

let crop = function(value, length) {
  if (value.length <= length) {
    return value
  }
  return value.slice(0, length - 3) + '...'
}

let replace = function(value, search, replacement) {
  return value.split(search).join(replacement)
}

let implode = function(array, separator) {
  if (!Array.isArray(array)) {
    return ''
  }
  return array.join(separator)
}

/**
 * Only allows EUR and USD currencies
 * @param value
 * @returns {string}
 */
let currency = function(value) {
  if (typeof value === 'undefined' || value === null) {
    value = ''
  }

  value = value.toUpperCase()

  switch (value) {
    case 'EUR':
      return '€'
    case 'USD':
      return '$'
    default:
      return ''
  }
}

let integer = function(value) {
  return parseInt(value)
}

let boolean = function(value) {
  if (value === 'true') {
    value = true
  }

  if (value === 'false') {
    value = false
  }

  return value ? 'Yes' : 'No'
}

let format = {
  number,
  price,
  crop,
  replace,
  implode,
  shortDate,
  shortMonth,
  shortWeek,
  currency,
  shortYear,
  integer,
  boolean
}

export default format
