import StoreHelper from './store_helper'
import ApiService from '../api/api'

const apiUrl = '/ui/domains'
const storeHelper = new StoreHelper(ApiService, apiUrl)
const state = () => {
  return {
    response_data: [],
    selectedDomain: null,
    batchUpdateDomains: [],
    selectedDomainsCount: null,
    fullPath: null
  }
}
const mutations = {
  ...storeHelper.getMutations(),
  setDomain: (state, data) => (state.selectedDomain = data),
  setBatchUpdateDomains: (state, data) => (state.batchUpdateDomains = data),
  setSelectedDomainsCount: (state, count) => (state.selectedDomainsCount = count),
  setFullPath: (state, fullPath) => (state.fullPath = fullPath)
}
const getters = {
  ...storeHelper.getGetters(),
  getBatchUpdateDomain: state => state.batchUpdateDomains,
  selectedDomainsCount: state => state.selectedDomainsCount,
  fullPath: state => state.fullPath
}
const actions = {
  ...storeHelper.getActions(),
  setSelectedDomainsCount: (context, count) => context.commit('setSelectedDomainsCount', count),
  setFullPath: (context, fullPath) => context.commit('setFullPath', fullPath),
  saveData: async (context, data) => {
    let response = await ApiService.put(apiUrl + '/' + data.domain, data.domainData)
    context.commit('setData', response.data)
    return response.data
  },
  store: async (context, data) => {
    let response = await ApiService.post(apiUrl, data, false)
    return response.data
  },
  setDomain: (context, domain) => context.commit('setDomain', domain),
  setBatchUpdateDomains: (context, domains) => context.commit('setBatchUpdateDomains', domains),
  fetchDomain: async (context, domain) => {
    if (context.state.selectedDomain !== null) {
      return context.state.selectedDomain
    } else {
      let response = await ApiService.get(apiUrl, { domain: domain })
      if (response.data && response.data.data && Array.isArray(response.data.data.data)) {
        storeHelper.getHelpers().castCustomTextToInteger(response)

        context.commit('setDomain', response.data.data.data[0])
      }
      return response.data.data.data[0]
    }
  }
}
export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true
}
