const state = () => ({
  snack: {
    color: '#D02541',
    message: '',
    subMessage: '',
    elevation: 0,
    timeout: 10000,
    top: false,
    bottom: false,
    details: [],
    show: false,
    multiLine: false,
    dismissable: false,
    useProgressIndicator: false,
    progressIndicatorColor: '#000',
    icon: null
  }
})

const getters = {
  snack: state => state.snack
}

const mutations = {
  setSnack(state, showSnack) {
    state.snack = { ...showSnack }
  },
  setShow(state, show) {
    state.snack = {
      show: show
    }
  }
}

const actions = {
  showDefaultMessage: ({ commit, state }, additionalArguments) => {
    commit('setSnack', {
      ...state.snack,
      ...{
        dismissable: false,
        top: true,
        color: 'primary',
        timeout: '-1',
        useProgressIndicator: true,
        progressIndicatorColor: '#fff',
        show: true,
        icon: null,
        subMessage: '',
        details: []
      },
      ...additionalArguments
    })
  },
  showErrorMessage: ({ commit, state }, additionalArguments) => {
    commit('setSnack', {
      ...state.snack,
      ...{
        dismissable: false,
        top: true,
        show: true,
        timeout: '5000',
        color: 'error',
        icon: 'mdi-close-thick',
        subMessage: '',
        details: [],
        useProgressIndicator: false
      },
      ...additionalArguments
    })
  },
  showWarningMessage: ({ commit, state }, additionalArguments) => {
    commit('setSnack', {
      ...state.snack,
      ...{
        dismissable: false,
        top: true,
        show: true,
        timeout: '5000',
        color: 'warning',
        icon: 'mdi-alert',
        subMessage: '',
        details: [],
        useProgressIndicator: false
      },
      ...additionalArguments
    })
  },
  showInfoMessage: ({ commit, state }, additionalArguments) => {
    commit('setSnack', {
      ...state.snack,
      ...{
        dismissable: false,
        top: true,
        show: true,
        timeout: '5000',
        color: 'info',
        icon: 'mdi-information',
        subMessage: '',
        details: [],
        useProgressIndicator: false
      },
      ...additionalArguments
    })
  },
  showSuccessMessage: ({ commit, state }, additionalArguments) => {
    commit('setSnack', {
      ...state.snack,
      ...{
        dismissable: false,
        top: true,
        show: true,
        timeout: '3000',
        color: 'success',
        icon: 'mdi-check-bold',
        subMessage: '',
        details: [],
        useProgressIndicator: false
      },
      ...additionalArguments
    })
  },
  hideMessage: ({ commit }) => {
    commit('setShow', false)
  }
}

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true
}
