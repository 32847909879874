<template>
  <v-list-item class="appbar-menu-level1 menu-button mb-0 pa-0" @click="$emit('buttonClick')">
    <v-list-item-icon class="menu-item-icon pl-2 mr-4">
      <v-icon>{{ icon }}</v-icon>
    </v-list-item-icon>
    <v-list-item-title>{{ label }}</v-list-item-title>
  </v-list-item>
</template>

<script>
export default {
  name: 'MenuButton',
  props: {
    icon: {
      String,
      required: true
    },
    label: {
      String,
      required: true
    }
  }
}
</script>
