class Logger {
  static log(...args) {
    if (process.env.VUE_APP_DEBUG_LOG_ENABLED === 'true') {
      console.log(...args)
    }
  }

  static info(...args) {
    if (process.env.VUE_APP_DEBUG_LOG_ENABLED === 'true') {
      console.info(...args)
    }
  }

  static debug(...args) {
    if (process.env.VUE_APP_DEBUG_LOG_ENABLED === 'true') {
      console.debug(...args)
    }
  }

  static warn(...args) {
    if (process.env.VUE_APP_WARNING_LOG_ENABLED === 'true') {
      console.warn(...args)
    }
  }

  static error(...args) {
    if (process.env.VUE_APP_ERROR_LOG_ENABLED === 'true') {
      console.error(...args)
    }
  }
}

export default Logger
