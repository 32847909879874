<template>
  <v-footer id="footer" class="text-center" absolute app inset>
    <v-container>
      <v-divider class="mb-5"></v-divider>
      <v-row>
        <v-col cols="12">
          <span class="pa-2">
            <a href="https://www.parkingcrew.com/" target="_blank">{{ $t('footer.about_pcrew') }}</a>
          </span>
          <span class="pa-2">
            <router-link to="/imprint">{{ $t('footer.imprint') }}</router-link>
          </span>
          <span class="pa-2">
            <a href="https://teaminternet.de/privacy.html" target="_blank">{{ $t('footer.privacy_policy') }}</a>
          </span>
        </v-col>
        <v-col cols="12">
          <span>&copy; {{ $t('footer.copyright') }} {{ new Date().getFullYear() }}</span>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
export default {}
</script>

<style lang="sass">
@import '~@/sass/variables.sass'

#footer
  background-color: $background

  a
    text-decoration: none
    color: $secondary-text !important

  a:hover
    color: $primary !important
</style>
