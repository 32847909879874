<template>
  <div class="d-flex ml-2" v-if="appEnv !== 'prod'">
    <span class="environment_label">{{ appEnv }}</span>
  </div>
</template>

<script>
export default {
  computed: {
    appEnv() {
      return process.env.VUE_APP_ENV
    }
  }
}
</script>

<style lang="sass">
@import '~@/sass/variables.sass'

#appbar
  .environment_label
    color: $logo-color
</style>
