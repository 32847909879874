import axios from 'axios'
import ErrorHelper from '../error/error'
import Logger from '@/util/Logger'
import LocalStore from '@/util/LocalStore'

const api_context = require(`@/api/context`).default

// Set default header
axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL
axios.defaults.headers.common['Content-Type'] = 'application/json'
axios.defaults.headers.common['Accept'] = 'application/json'

class Api {
  // for multiple requests
  isRefreshing = false
  promiseObjectsQueue = []

  // holds the time in seconds before token expiry
  secondsBeforeTokenExpireToRefresh = 30

  /**
   * Sends request to API.
   *
   * If response is 401, tokens will automatically get refreshed and original request repeated
   * (except for public endpoints)
   *
   * @param config axios request config
   * @returns Promise
   */
  async request(config) {
    Logger.log('[Api] sending request', config)

    // set authorization header from auth store, if present
    config.headers = config.headers || {}
    if (api_context.getAuthData().access_token) {
      config.headers['Authorization'] = 'Bearer ' + api_context.getAuthData().access_token
    }
    config.headers['X-Api-Client-Source'] = 'ui'

    // if not public endpoint and access token is about to expire
    if (!api_context.isPublicEndpoint(config.url) && this.isItTimeToRefresh()) {
      // if we are in the refreshing process: add requests to queue array
      return this.refreshTokenOrQueueRequest(config)
    } else {
      if (LocalStore.hasFixedParams()) {
        const fixedParams = LocalStore.getFixedParams()

        if ('debug_params' in fixedParams) {
          let debugParams = fixedParams['debug_params']

          config.params = { ...config.params, ...debugParams }
        }
      }

      let response = await axios
        .request(config)
        .catch(error => this.forwardErrorOrRepeatRequestWithRefreshToken(error))
        .catch(error => this.forwardOrHandleError(error))

      // every get request (besides download) with status 200 should contain a data node, otherwise we trigger an error
      let downloadResponseTypes = ['blob', 'arraybuffer']
      let isDownloadResponse = downloadResponseTypes.includes(response.config.responseType)
      if (!isDownloadResponse) {
        let is200GetResponse = response.status === 200 && config.method === 'get'
        let hasDataNode = response.data && response.data.data
        if (is200GetResponse && !hasDataNode) {
          throw Error('[Api] Get response has no data')
        }
      }
      // success
      Logger.log('[Api] request success: ', response)
      return response
    }
  }

  /**
   * Returns true if the token will expire soon and refreshing should be done.
   *
   * @returns {boolean}
   */
  isItTimeToRefresh() {
    if (api_context.getAuthData().expires_at) {
      let tokenWillExpireAt = parseInt(api_context.getAuthData().expires_at)
      let milliSecondsBeforeExpireToRefresh = this.secondsBeforeTokenExpireToRefresh * 1000
      let earliestTimeToRefresh = tokenWillExpireAt - milliSecondsBeforeExpireToRefresh
      let currentTimeInMilliseconds = Date.now()
      Logger.info('[Api] Refresh token info: earliestTimeToRefresh', new Date(earliestTimeToRefresh))
      return currentTimeInMilliseconds > earliestTimeToRefresh
    }
    return false
  }

  /**
   * Refreshes the tokens or writes the request to a queue if refresh is in progress.
   *
   * @param config
   * @returns {Promise<Promise>|Promise<void>}
   */
  refreshTokenOrQueueRequest(config) {
    if (this.isRefreshing) {
      Logger.log('[Api] push into request queue: ', config.url)
      return this.writePromiseObjectsToQueue(config)
    }
    // do refresh token
    return this.refreshToken(config)
  }

  /**
   * Function to refresh all tokens
   *
   * @param config
   * @returns {Promise<void>}
   */
  async refreshToken(config) {
    if (!api_context.getAuthData().refresh_token) {
      Logger.warn('[Api] Trigger refreshToken but no refresh token exists', { config })
      return Promise.reject(config)
    }
    if (this.isRefreshing) {
      Logger.warn('[Api] Trigger refreshToken but refresh is in progress', { config })
      return Promise.reject(config)
    }
    if (config._retry) {
      Logger.warn('[Api] Trigger refreshToken in _retry', { config })
      return Promise.reject(config)
    }

    this.isRefreshing = true
    config._retry = true
    Logger.log('[Api] START Refresh the tokens')

    try {
      let data = { refresh_token: api_context.getAuthData().refresh_token, set_cookie: 1 }
      let response = await this.post(api_context.getRefreshTokenRoute(), data, { withCredentials: true })
      api_context.setAuthData(response.data)
      Logger.log('[Api] got refresh token, retrying original request')
    } catch (error) {
      api_context.clearAuthData()
      this.processQueue(error)
      Logger.log('[Api] Refresh token failed', { error })
      api_context.onTokenRefreshFailed()
      throw error
    } finally {
      this.isRefreshing = false
    }

    if (!config.url) {
      // no request to repeat after token refresh
      return Promise.resolve()
    }

    this.processQueue(null)
    return await this.request(config)
  }

  /**
   * @param config
   * @returns {Promise<void>}
   */
  async fetchNewTokenIfRequired(config) {
    if (!this.isItTimeToRefresh()) {
      return Promise.resolve()
    }
    return this.refreshToken(config)
  }

  /**
   * Write requests to promiseObjectsQueue
   *
   * @param config
   * @returns {Promise<Promise>}
   */
  writePromiseObjectsToQueue(config) {
    return new Promise((resolve, reject) => {
      this.promiseObjectsQueue.push({ resolve, reject })
    })
      .then(() => {
        return this.request(config)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  }

  /**
   * Go through request queue after refresh the tokens
   *
   * @param error
   */
  processQueue(error) {
    this.promiseObjectsQueue.forEach(prom => {
      if (error) {
        prom.reject(error)
      } else {
        prom.resolve()
      }
    })

    this.promiseObjectsQueue = []
  }

  /**
   * Shows the error in the log or snackbar or just forwards it to the caller.
   *
   * @param error
   * @returns {Promise<never>}
   */
  forwardOrHandleError(error) {
    // on public endpoints we want to handle the error in the component
    let url = error.config && error.config.url ? error.config.url : ''
    let isPrivateEndpoint = !api_context.isPublicEndpoint(url)

    // the lists of error that can be ignore could be extended here
    let shouldBeShown = isPrivateEndpoint
    if (shouldBeShown) {
      // shows the error in if snackbar
      ErrorHelper.show(error)
    }

    // return rejected promise in order to allow further error handling
    return Promise.reject(error)
  }

  /**
   * Handles error response
   *
   * @param error error response object
   * @returns Promise
   */
  async forwardErrorOrRepeatRequestWithRefreshToken(error) {
    if (!error.response) {
      Logger.log('[Api] No response in error: ', error)
      throw error
    }

    Logger.log('[Api] request error: ', error.response, error.config)

    // everything not 401: resume error handling
    if (error.response.status !== 401) {
      throw error
    }

    // public endpoints: resume error handling
    if (api_context.isPublicEndpoint(error.config.url)) {
      throw error
    }

    // error in retry request
    if (error.config && error.config._retry === true) {
      throw error
    }

    // refresh tokens
    Logger.log('[Api] got 401, refreshing tokens')
    return this.refreshTokenOrQueueRequest(error.config)
  }

  /**
   * Send GET request to API
   *
   * @param url url of endpoint (relative to api base url)
   * @param params object with query params
   * @param responseType for example export 'blob'
   * @returns {Promise}
   */
  get(url, params, responseType = '') {
    let config = { method: 'get', url: url, params: params }
    if (responseType) {
      config.responseType = responseType
    }
    return this.request(config)
  }

  /**
   * Send POST request to API
   *
   * @param url url of endpoint (relative to api base url)
   * @param data post data
   * @param additionalAxiosConfig additional axios config
   * @returns Promise
   */
  post(url, data, additionalAxiosConfig = {}) {
    return this.request({ method: 'post', url: url, data: data, ...additionalAxiosConfig })
  }

  /**
   * Send PUT request to API
   *
   * @param url url of endpoint (relative to api base url)
   * @param data put data
   * @returns {Promise}
   */
  put(url, data) {
    return this.request({ method: 'put', url: url, data: data })
  }

  /**
   * Send DELETE request to API
   *
   * @param url url of endpoint (relative to api base url)
   * @returns Promise
   */
  delete(url) {
    return this.request({ method: 'delete', url: url })
  }
}

export default new Api()
