import snackbar from './snackbar'
import auth from './auth'
import user from './user'
import domain from './domain'
import domainstats from './domainstats'
import folder from './folder'
import compact_view from './compact_view'
import sale_banner from './sale_banner'
import sale_template from './sale_template'
import sale_text from './sale_text'
import sale_url from './sale_url'
import stats_user from './stats_user'
import stats_user_kpi from './stats_user_kpi'
import stats_user_date from './stats_user_date'
import stats_user_week from './stats_user_week'
import stats_user_month from './stats_user_month'
import stats_user_year from './stats_user_year'
import stats_user_domain_date from './stats_user_domain_date'
import stats_user_domain_week from './stats_user_domain_week'
import stats_user_domain_month from './stats_user_domain_month'
import stats_user_domain_year from './stats_user_domain_year'
import finished_imports_pending from './finished_imports_pending'
import user_permissions from './user_permissions'
import new_domain_upload from './new_domain_upload'
import contact from './contact'
import batch from './batch'
import bulk_request from './bulk_request'
import deleted_domain from './deleted_domain'

const modules = {
  snackbar,
  auth,
  user,
  domain,
  domainstats,
  folder,
  sale_banner,
  sale_template,
  sale_text,
  sale_url,
  stats_user,
  stats_user_kpi,
  stats_user_date,
  stats_user_week,
  stats_user_month,
  stats_user_year,
  stats_user_domain_date,
  stats_user_domain_week,
  stats_user_domain_month,
  stats_user_domain_year,
  finished_imports_pending,
  compact_view,
  user_permissions,
  new_domain_upload,
  contact,
  batch,
  bulk_request,
  deleted_domain
}

export default modules
