import ApiService from '../api/api'

const actions = {
  fetchData: async () => {
    let response = await ApiService.get('/ui/stats/kpi')
    return response.data
  }
}

export default {
  actions,
  namespaced: true
}
