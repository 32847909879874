import StoreHelper from './store_helper'
import ApiService from '../api/api'

const apiUrl = '/ui/deleted_domains'
const storeHelper = new StoreHelper(ApiService, apiUrl)
const state = storeHelper.getInitialState()
const getters = storeHelper.getGetters()
const mutations = storeHelper.getMutations()
const actions = storeHelper.getActions()

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true
}
