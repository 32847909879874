import StoreHelper from './store_helper'
import ApiService from '../api/api'

const apiUrl = '/ui/new_domain_uploads'
const storeHelper = new StoreHelper(ApiService, apiUrl)
const state = storeHelper.getInitialState()
const getters = storeHelper.getGetters()
const mutations = storeHelper.getMutations()

const actions = {
  ...storeHelper.getActions(),
  store: async (context, data) => {
    let response = await ApiService.post(apiUrl, data)
    return response.data
  }
}

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true
}
