import store from '../store'
import i18n from '../i18n'
import Logger from '@/util/Logger'

class ErrorHelper {
  /**
   * This method is used to show the error either in the snackbar or the console.
   *
   * @param error
   * @param showInConsole
   * @param showInSnackbar
   */
  show(error, showInConsole = true, showInSnackbar = true) {
    let isApiError = typeof error.response === 'object' && typeof error.response.data === 'object'
    let errorHasMessage = typeof error.message === 'string'

    let message = typeof error === 'string' ? error : i18n.t('errors.unknown')
    let details = []
    if (isApiError === true) {
      message = i18n.t('errors.api', { msg: error.response.data.message })
      details = this.getDetailsFromApiError(error)
    } else if (errorHasMessage === true) {
      message = i18n.t('errors.general', { msg: error.message })
    }

    if (showInConsole) {
      this.writeInConsole(message, details)
    }

    if (showInSnackbar) {
      this.writeInSnackBar(message)
    }
  }

  /**
   * console.log is in extra function because of jest tests
   *
   * @param message
   * @param details
   */
  writeInConsole(message, details) {
    Logger.log({ message, details })
  }

  /**
   * store.dispatch is in extra function because of jest tests
   *
   * @param message
   * @param details
   */
  writeInSnackBar(message) {
    store.dispatch('snackbar/showErrorMessage', { message })
  }

  /**
   * This method parses the error details from the api and returns an array with the details.
   *
   * @param error
   * @returns {[]}
   */
  getDetailsFromApiError(error) {
    let details = []
    if (typeof error.response.data !== 'undefined' && typeof error.response.data.errors !== 'undefined') {
      // map laravel structure to error details
      Object.values(error.response.data.errors).forEach(function(value) {
        let serverMessage = value[0]
        let translatedDetailMessage = i18n.t('errors.' + serverMessage)
        details.push(translatedDetailMessage)
      })
    }

    if (typeof error.response.status !== 'undefined' && typeof error.response.statusText !== 'undefined') {
      let statusMessage = `StatusCode: ${error.response.status}, StatusText: ${error.response.statusText}`
      details.push(statusMessage)
    }

    return details
  }
}

export default new ErrorHelper()
