import StoreHelper from './store_helper'
import ApiService from '../api/api'
import BatchHelper from '@/util/BatchHelper'

const apiUrl = '/ui/batches'
const storeHelper = new StoreHelper(ApiService, apiUrl)
const state = storeHelper.getInitialState()
const getters = storeHelper.getGetters()
const mutations = storeHelper.getMutations()

const actions = {
  ...storeHelper.getActions(),
  store: async (context, data) => {
    let response = await ApiService.post(apiUrl, data, false)

    let batchResponses = response?.data?.data ?? []
    let errorIds = BatchHelper.getIdsErrorMessages(batchResponses)
    let errorMessages = BatchHelper.getUniqueErrorMessages(batchResponses)

    return { response, errorIds, errorMessages }
  }
}

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true
}
