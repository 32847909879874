<template>
  <router-link id="appbar-logo" to="/dashboard">
    <ParkingCrewLogoSmall />
  </router-link>
</template>

<script>
import ParkingCrewLogoSmall from './logo/ParkingCrewLogoSmall'

export default {
  components: {
    ParkingCrewLogoSmall
  }
}
</script>

<style lang="sass">
#appbar-logo
  height: 26px
</style>
