<template>
  <v-snackbar
    v-model="show"
    :bottom="snack.bottom"
    :top="snack.top"
    :color="snack.color"
    :multi-line="snack.multiLine"
    :elevation="snack.elevation"
    :timeout="snack.timeout"
    :min-height="60"
  >
    <div>
      <v-row align="center">
        <v-col cols="2" v-if="snack.useProgressIndicator">
          <v-progress-circular indeterminate :color="snack.progressIndicatorColor"></v-progress-circular>
        </v-col>
        <v-col cols="2" v-if="snack.icon != null">
          <v-icon>{{ snack.icon }}</v-icon>
        </v-col>
        <v-col cols="10" :class="{ 'pt-4': snack.useProgressIndicator || snack.icon != null, 'text-center': snack.subMessage === '' }">
          {{ snack.message }}
        </v-col>
        <v-col cols="10" offset="2" class="pt-0" v-if="snack.subMessage">
          {{ snack.subMessage }}
        </v-col>
      </v-row>

      <ul v-if="snack.details">
        <li v-for="detail in snack.details" :key="detail">
          {{ detail }}
        </li>
      </ul>
    </div>
    <template v-slot:action="{ attrs }" v-if="snack.dismissable">
      <v-btn rounded color="red" fab x-small dark v-bind="attrs" @click="show = false">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  data() {
    return {
      show: false
    }
  },
  watch: {
    snack(snack) {
      this.show = snack.show
    }
  },
  computed: {
    snack() {
      return this.$store.getters['snackbar/snack']
    }
  }
}
</script>
