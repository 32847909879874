import Logger from '@/util/Logger'
import LocalStore from '@/util/LocalStore'
import { v4 as uuidv4 } from 'uuid'

class CookieBotHandler {
  log = message => {
    Logger.log(message)
  }

  getLocalStore = () => {
    return LocalStore
  }

  cookiebotOnAcceptOrDecline = () => {
    this.log('CookiebotOnAcceptOrDecline')

    // statistics
    if (window.Cookiebot.consent.statistics) {
      this.log('Cookiebot: statistics enabled')
      this.log('Matomo: set cookie consent')
      window._paq.push(['setCookieConsentGiven'])
      this.log('Matomo: set consent')
      window._paq.push(['setConsentGiven'])
      this.log('Matomo: enable heatmap')
      window._paq.push(['HeatmapSessionRecording::enable'])
      let hasMatomoId = this.getLocalStore().hasMatomoId()
      if (!hasMatomoId) {
        this.log('Matomo: set visitor id')
        let newId = uuidv4()
        // set for later usage
        this.getLocalStore().setMatomoId(newId)
        // set to current session
        window._paq.push(['setUserId', newId])
      }
    } else {
      this.log('Cookiebot: statistics disabled')
      this.log('Matomo: remove cookie consent')
      window._paq.push(['forgetCookieConsentGiven'])
      this.log('Matomo: remove consent')
      window._paq.push(['forgetConsentGiven'])
      this.log('Matomo: disable heatmap')
      window._paq.push(['HeatmapSessionRecording::disable'])

      this.log('Matomo: reset visitor id')
      this.getLocalStore().removeMatomoId()
    }

    // marketing
    if (window.Cookiebot.consent.marketing) {
      this.log('Cookiebot: marketing enabled')
      this.log('Cookiebot: currently no marketing cookies used!')
    } else {
      this.log('Cookiebot: marketing disabled')
      this.log('Cookiebot: currently no marketing cookies used!')
    }

    // preferences
    if (window.Cookiebot.consent.preferences) {
      this.log('Cookiebot: preferences enabled')
      this.log('Cookiebot: currently no preferences cookies used!')
    } else {
      this.log('Cookiebot: preferences disabled')
      this.log('Cookiebot: currently no preferences cookies used!')
    }
  }
}

export default {
  install() {
    const cookiebotScript = document.createElement('script')
    cookiebotScript.setAttribute('id', 'Cookiebot')
    cookiebotScript.setAttribute('src', 'https://consent.cookiebot.com/uc.js')
    cookiebotScript.setAttribute('data-cbid', 'f4401935-112b-4206-a4d9-6cac6e4fae05')
    cookiebotScript.setAttribute('type', 'text/javascript')
    cookiebotScript.setAttribute('async', '')
    document.head.appendChild(cookiebotScript)

    let cookieBotHandler = this.getCookieBotHandler()
    window.addEventListener('CookiebotOnAccept', cookieBotHandler.cookiebotOnAcceptOrDecline)
    window.addEventListener('CookiebotOnDecline', cookieBotHandler.cookiebotOnAcceptOrDecline)
  },
  getCookieBotHandler() {
    return new CookieBotHandler()
  }
}
