<template>
  <v-navigation-drawer v-if="loggedIn" v-model="isVisible" fixed top :temporary="$vuetify.breakpoint.mobile" id="navdrawer" app>
    <v-list nav expand>
      <MenuItem :link="{ name: 'dashboard' }" icon="mdi-view-dashboard-outline" :label="$t('nav_drawer.dashboard')" />
      <MenuItem :link="{ name: 'domains_add' }" icon="mdi-plus-box-outline" :label="$t('nav_drawer.domains_add')" />
      <MenuItemDropdown icon="mdi-tune" :label="$t('nav_drawer.manage_portfolio')" :open-group="openDropdownMenu" group-name="portfolio">
        <template v-slot:children>
          <SubMenuItem :link="{ name: 'domains_index', query: { is_deleted: 0 } }" :label="$t('nav_drawer.domains_overview')" />
          <SubMenuItem :link="{ name: 'folders_index' }" :label="$t('nav_drawer.folders_overview')" />
        </template>
      </MenuItemDropdown>
      <MenuItemDropdown icon="mdi-poll" :label="$t('nav_drawer.domains_performance')" :open-group="openDropdownMenu" group-name="statistics">
        <template v-slot:children>
          <SubMenuItem :link="{ name: 'stats_domains', query: { is_deleted: 0 } }" icon="mdi-speedometer" :label="$t('nav_drawer.domains_stats')" />
          <SubMenuItem :link="{ name: 'stats_index' }" icon="mdi-finance" :label="$t('nav_drawer.account_stats')" />
          <SubMenuItem :link="{ name: 'stats_exports' }" icon="mdi-file-chart-outline" :label="$t('nav_drawer.exports')" />
        </template>
      </MenuItemDropdown>
      <MenuItemDropdown :label="$t('nav_drawer.tools')" icon="mdi-wrench-outline" :open-group="openDropdownMenu" group-name="tools">
        <template v-slot:children>
          <SubMenuItem :link="{ name: 'tools_background_jobs' }" :label="$t('nav_drawer.background_jobs')" />
          <SubMenuItem :link="{ name: 'tools_blocked_adult_check' }" :label="$t('nav_drawer.blocked_adult_check')" />
          <SubMenuItem :link="{ name: 'tools_nameserver_check' }" :label="$t('nav_drawer.nameserver_check')" />
        </template>
      </MenuItemDropdown>
      <MenuItem :link="{ name: 'domains_deleted' }" icon="mdi-delete-outline" :label="$t('nav_drawer.domains_deleted')" />
      <v-divider class="my-7" />
      <MenuItem :link="{ name: 'api_documentation' }" icon="mdi-code-tags" :label="$t('help.heading')" />
      <MenuItem :link="{ name: 'support' }" icon="mdi-chat-question-outline" :label="$t('contact_support.heading')" />
      <MenuItem :link="{ name: 'account_index' }" icon="mdi-account-outline" :label="$t('account_home.heading')" />
      <MenuItem :link="{ name: 'logout' }" icon="mdi-logout" :label="$t('auth.logout')" />

      <MenuButton @buttonClick="handleTestEndClick" icon="mdi-close" label="End Testing Mode" v-if="isTestingMode" />
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import MenuItem from './navdrawer/MenuItem'
import MenuItemDropdown from './navdrawer/MenuItemDropdown'
import SubMenuItem from './navdrawer/SubMenuItem'
import MenuButton from './navdrawer/MenuButton'
import LocalStore from '@/util/LocalStore'

export default {
  name: 'AppNavigation',
  components: {
    MenuItem,
    MenuItemDropdown,
    SubMenuItem,
    MenuButton
  },
  props: {},
  methods: {
    handleTestEndClick() {
      LocalStore.removeFixedParams()
      window.location.href = window.location.href.split('?')[0]
    },
    toggleIsVisible() {
      this.isVisible = !this.isVisible
    }
  },
  watch: {
    '$route.meta': function() {
      if (typeof this.$route.meta.parent !== 'undefined') {
        this.openDropdownMenu = this.$route.meta.parent
      } else {
        this.openDropdownMenu = ''
      }
    }
  },
  data() {
    return {
      isVisible: !this.$vuetify.breakpoint.mobile,
      openDropdownMenu: ''
    }
  },
  computed: {
    loggedIn() {
      return this.$store.getters['auth/loggedIn']
    },
    isTestingMode() {
      if (!LocalStore.hasFixedParams()) {
        return false
      }

      let fixedParams = LocalStore.getFixedParams()
      return 'ab_test_params' in fixedParams || 'debug_params' in fixedParams
    }
  }
}
</script>

<style lang="sass">
@import '~@/sass/variables.sass'

#navdrawer
  // set background color
  background-color: $background

  // need to adjust top padding to appbar height
  padding-top: 60px

  // background of the active menu item link
  a.active::before
    background: none

  // color of the active menu item link
  a.active
    color: $primary
</style>
