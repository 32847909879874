<template>
  <v-list-group class="menu-item-dropdown" active-class="active" :value="isOpen">
    <template v-slot:activator>
      <v-list-item class="appbar-menu-level1 mb-0 px-0">
        <v-list-item-icon class="menu-item-icon mr-3">
          <v-icon>{{ icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-title class="menu-item-dropdown-title">{{ label }}</v-list-item-title>
      </v-list-item>
    </template>
    <slot name="children"></slot>
  </v-list-group>
</template>

<script>
export default {
  name: 'MenuItemDropdown',
  props: {
    groupName: {
      String,
      required: true
    },
    icon: {
      String,
      required: true
    },
    label: {
      String,
      required: true
    },
    openGroup: {
      String,
      required: false,
      default: ''
    }
  },
  computed: {
    isOpen() {
      return this.openGroup === this.groupName
    }
  }
}
</script>

<style lang="sass">
@import '~@/sass/variables.sass'
#navdrawer
  // fix margin/bottom for expand icon when scrollbar appears
  .v-list-group__header
    margin-bottom: 0
    padding-right: 0 !important
  // fix margin and active color for group expand icon
  .v-list-group__header__append-icon
    color: $primary-text
    margin-left: 0
    margin-right: 4px
</style>
