import Logger from '@/util/Logger'
import LocalStore from '@/util/LocalStore'

const isInvalidCandidateParam = ctx => {
  return ctx.props.candidateParam !== undefined && !ctx.props.candidateParam.startsWith('ab_test')
}

const fetchFromUrlQuery = ctx => {
  return ctx.parent.$route.query[ctx.props.candidateParam]
}

/**
 * Object.hasOwn is only available from Nodejs 16.9.0
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/hasOwn#browser_compatibility
 */
const hasObjectProperty = (object, propertyName) => {
  return object[propertyName] !== undefined
}

const renderDefaultCandidate = (availableCandidates, context) => {
  return availableCandidates[context.props.defaultCandidate]
}

const isUnavailableCandidate = (candidateKey, abTestCandidateObject, availableCandidates) => {
  return !hasObjectProperty(availableCandidates, abTestCandidateObject[candidateKey])
}

const cleanUpCandidateInLocalStorage = fixedParams => {
  delete fixedParams.ab_test_params
  LocalStore.setFixedParams(fixedParams)
}

const VueAB = {
  install(Vue) {
    Vue.component('ab-test', {
      functional: true,
      props: {
        candidateParam: String,
        defaultCandidate: String
      },
      render(createElement, ctx) {
        const variations = ctx.slots()

        if (isInvalidCandidateParam(ctx)) {
          Logger.log('invalid candidate param name')
          return
        }
        // parse from query first, if not present in query try to grab from localStorage
        let candidate = fetchFromUrlQuery(ctx)

        if (candidate === undefined) {
          let fixedParams = LocalStore.getFixedParams()

          if (fixedParams === null || !hasObjectProperty(fixedParams, 'ab_test_params')) {
            return renderDefaultCandidate(variations, ctx)
          }

          if (isUnavailableCandidate(ctx.props.candidateParam, fixedParams.ab_test_params, variations)) {
            cleanUpCandidateInLocalStorage(fixedParams)
            return renderDefaultCandidate(variations, ctx)
          }

          candidate = fixedParams.ab_test_params[ctx.props.candidateParam]
        }

        return variations[candidate]
      }
    })
  }
}

export default VueAB
