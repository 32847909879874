export default {
  background: '#FAFAFD',
  sheet_background: '#FFFFFF',
  sheet_border: '#CDCDCD',
  primary: '#FF5722',
  primary_light: '#FAEDEC',
  secondary: '#435660',
  header_gradient_left: '#435660',
  header_gradient_right: '#435660',
  logo_color: '#FF5722',
  primary_text: '#1F1D1B',
  secondary_text: '#85827F',
  input_background: '#F3F3F3',
  input_border: '#CDCDCD',
  error: '#B71C1C',
  info: '#2196F3',
  success: '#25952A',
  warning: '#FFA000'
}
