<template>
  <v-list-item :to="link" class="appbar-menu-level2 sub-menu-item mb-0 py-1 pl-11" active-class="active">
    <v-list-item-title>{{ label }}</v-list-item-title>
  </v-list-item>
</template>

<script>
export default {
  name: 'SubMenuItem',
  props: {
    link: {
      Object
    },
    label: {
      String,
      required: true
    }
  }
}
</script>
