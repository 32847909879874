<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 1 114 68" class="parkingcrew-logo-small">
    <g>
      <path d="M55.68,26.84V69.42h-15V26.84Z" />
      <path d="M35.34,42.91V69.42h-15V42.91Z" />
      <path d="M15,51.08V69.42H0V51.08Z" />
      <path
        d="M107.56,39.93q-6,5-17.08,5H76.29v24.5H62.15V1.29H91.4q10.11,0,16.13,5.27t6,16.32Q113.54,34.94,107.56,39.93ZM96.7,15.39c-1.81-1.51-4.33-2.26-7.58-2.26H76.29v20H89.12c3.25,0,5.77-.81,7.58-2.45s2.7-4.22,2.7-7.76S98.5,16.9,96.7,15.39Z"
      />
    </g>
  </svg>
</template>

<script>
export default {}
</script>

<style lang="sass">
@import '~@/sass/variables.sass'

.parkingcrew-logo-small
  display: inline-block
  height: 26px
  path
    fill: $logo-color
</style>
