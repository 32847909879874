import Api from '../api/api'
import router from '../router/router'
import ApiService from '@/api/api'
import Logger from '@/util/Logger'
import LocalStore from '@/util/LocalStore'

// auth state contains access and refresh token
const state = () => ({
  access_token: LocalStore.getPcAccessToken(),
  refresh_token: LocalStore.getPcRefreshToken(),
  expires_at: LocalStore.getPcExpiresAt()
})

const getters = {
  loggedIn: state => !!state.access_token,
  accessToken: state => state.access_token
}

const mutations = {
  /**
   * Sets auth state from api response data
   *
   * @param state current state
   * @param authResponseData api response data
   */
  setAuthResponseData(state, authResponseData) {
    state.access_token = authResponseData.access_token
    state.refresh_token = authResponseData.refresh_token
    state.expires_at = Date.now() + authResponseData.expires_in * 1000
    LocalStore.setPcAccessToken(state.access_token)
      .setPcRefreshToken(state.refresh_token)
      .setPcExpiresAt(state.expires_at)
    Logger.log('[Auth] set auth response data:', state)
  },

  /**
   * Clears tokens from state and cache
   *
   * @param state current state
   */
  clearAuthResponseData(state) {
    state.access_token = null
    state.refresh_token = null
    state.expires_at = null

    LocalStore.removePcAccessToken()
      .removePcRefreshToken()
      .removePcExpiresAt()
    Logger.log('[Auth] cleared auth response data')
  }
}

const actions = {
  /**
   * Login user
   *
   * @param context store context
   * @param credentials credentials (username and password)
   * @returns Promise
   */
  loginUser: async (context, credentials) => {
    try {
      let loginArguments = { ...credentials, set_cookie: 1 }
      let response = await Api.post('/ui/auth/login', loginArguments, { withCredentials: true })
      context.commit('setAuthResponseData', response.data)
      return response.data
    } catch (error) {
      context.commit('clearAuthResponseData')
      throw error
    }
  },

  /**
   * Logout user
   * We need here sync api call, because /ui/auth/logout needs access_token
   * so please do clearAuthResponseData after we have a response or error
   *
   * @param context store context
   * @returns Promise
   */
  logoutUser: async context => {
    try {
      let response = await Api.post('/ui/auth/logout')
      context.commit('clearAuthResponseData')
      await router.push({ path: '/auth/login' })
      return response
    } catch (error) {
      context.commit('clearAuthResponseData')
      await router.push({ path: '/auth/login' })
      throw error
    }
  },
  forceTokenRefreshWhenRequired: async () => {
    return ApiService.fetchNewTokenIfRequired({})
  }
}

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true
}
