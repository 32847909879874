import StatsHelper from './stats_helper'
import ApiService from '../api/api'

const statsHelper = new StatsHelper(ApiService, '/ui/stats/month')
const state = () => statsHelper.getInitialState()
const mutations = statsHelper.getMutations()
const getters = statsHelper.getGetters()
const actions = statsHelper.getActions()

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true
}
