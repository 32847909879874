export default {
  background: '#FAFAFD',
  sheet_background: '#FFFFFF',
  sheet_border: '#CDCDCD',
  primary: '#9F5942',
  primary_light: '#F2EDEE',
  secondary: '#004970',
  header_gradient_left: '#C65835',
  header_gradient_right: '#9F5942',
  logo_color: '#FFFFFF',
  primary_text: '#1F1D1B',
  secondary_text: '#85827F',
  input_background: '#F3F3F3',
  input_border: '#CDCDCD',
  error: '#B71C1C',
  info: '#2196F3',
  success: '#25952A',
  warning: '#FFA000'
}
