import StoreHelper from './store_helper'
import ApiService from '../api/api'

const storeHelper = new StoreHelper(ApiService, '/ui/sale_templates')
const state = () => storeHelper.getInitialState()
const mutations = storeHelper.getMutations()
const getters = {
  ...storeHelper.getGetters(),
  getByType: state => type => {
    if (!state.response_data.data || !state.response_data.data.data) {
      return []
    }

    return state.response_data.data.data.filter(item => item.type == type)
  },
  getSaleTemplateNameById: state => id => {
    let template = getters.getById(state)(id)
    if (template == null) {
      return ''
    }
    return template.name
  }
}
const actions = storeHelper.getActions()

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true
}
