import StoreHelper from './store_helper'
import ApiService from '../api/api'

const storeHelper = new StoreHelper(ApiService, '/ui/sale_texts')
const state = () => storeHelper.getInitialState()
const mutations = storeHelper.getMutations()
const getters = {
  ...storeHelper.getGetters(),
  getSaleTexts: state => () => {
    if (!state.response_data.data || !state.response_data.data.data) {
      return []
    }

    return state.response_data.data.data
  },
  getSaleTextById: state => id => {
    let sale_text = getters.getById(state)(id)
    if (sale_text == null) {
      return []
    }

    return sale_text.text
  }
}
const actions = {
  ...storeHelper.getActions(),
  fetchData: async (context, data) => {
    let apiUrl = storeHelper.apiUrl

    if (storeHelper.fetchDataParams.length > 0) {
      apiUrl += storeHelper.fetchDataParams
    }

    let response = await storeHelper.apiService.get(apiUrl, data).catch(error => {
      context.commit('setData', this.getInitialState().response_data)
      throw error
    })

    let geoOptimized = [{ id: 0, text: ['Geo optimized language - Buy this domain.'] }]
    let merged = { data: { data: geoOptimized.concat(response.data.data.data) } }

    context.commit('setData', merged)
    return merged
  },
  saveData: async (context, data) => {
    let response = await ApiService.post(context.getters.getApiUrl, data)
    context.commit('setData', response.data)
    return response.data
  }
}

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true
}
